<template>
  
  <div class="home">
    <div id="app">
    <!-- 头部-->
    <Header></Header>
    <router-view />
  </div>
    <!-- 轮播图 -->
    <Banner :isBanner="true"></Banner>
    <!-- 内容 -->
    <div class="content">
    
      <div class="container" >
        <h2>{{ introductionData.title }}</h2>
        <p class="en">{{ introductionData.company }}</p>
        <div class="text">
          <p>{{ introductionData.contentOne }}</p>
        </div>
      
      </div>
     
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import { getIntroduction, getServiceContent, getSuccessData } from '@/api'
// 引入头部
import Header from '@/layout/header'
// 引入轮播图
import Banner from '@/layout/banner'
//引入底部
import Footer from '@/layout/footer'
//引入侧边导航栏
import Affix from '@/layout/affix'

export default {
  name: 'HomeView',
  data: function () {
    return {
     //公司简介的数据
      introductionData: {
        title: 'ABOUT US',
        company: '杭州翩若惊鸿网络科技有限公司',
        contentOne:
          '杭州翩若惊鸿网络科技有限公司是一家位于中国杭州的科技公司，专注于互联网领域的研发和创新。该公司的名称取自中国古代文学名著《红楼梦》中的一句诗句，寓意着公司追求卓越和优雅的品牌形象。作为一家互联网科技公司，杭州翩若惊鸿网络科技有限公司致力于为客户提供高质量的软件和网络解决方案。他们的服务范围涵盖了网站开发、移动应用开发、电子商务解决方案、大数据分析和人工智能等领域。该公司注重技术创新和研发能力的提升，拥有一支由经验丰富的工程师和技术专家组成的团队。他们致力于应用最新的技术和工具来开发创新的解决方案，以满足不同客户的需求。除了技术能力，杭州翩若惊鸿网络科技有限公司也注重与客户的合作关系。他们重视与客户的沟通和理解，以确保能够为客户提供量身定制的解决方案，并在项目开发过程中保持良好的合作关系。作为杭州的一家科技公司，杭州翩若惊鸿网络科技有限公司也受益于该地区的创新生态系统和互联网产业的蓬勃发展。杭州作为中国互联网行业的重要中心之一，拥有许多科技企业和创业公司，提供了广阔的市场和合作机会。总之，杭州翩若惊鸿网络科技有限公司是一家致力于为客户提供高质量互联网解决方案的科技公司，他们注重技术。',
        customer: 800,
        project: 1600,
        year: 13,
        satisfied: 98,
      }, 
     
    }
  },
  methods: {
    // 获取带图片的导航列表
    async getServiceContent() {
      const result = await getServiceContent()
      // this.iconNavList = result.data.data
    },
    //  获取成功案例的数据
    async getSuccessData() {
      const result = await getSuccessData()
      // this.successData = result.data
    },
    async getIntroduction() {
      const result = await getIntroduction()
      // this.introductionData = result.data
    },
    //  点击按钮跳转到媒体中心
    goMediaCenter() {
      this.$router.push({ path: '/mediaCenter' })
    },
    goBusinessCase() {
      this.$router.push({ path: '/businessCase' })
    },
    //  跳转页面
    goPage(url) {
      this.$router.push(url)
    },
    goCaseDetail() {
      this.$router.push({ path: '/caseDetail' })
    },
    gochemicalPlant() {
      this.$router.push({ path: '/chemicalPlant' })
    },
    goSite() {
      this.$router.push({ path: '/site' })
    },
    goMediaContent() {
      this.$router.push({ path: '/mediaContent' })
    },
    goSjrg() {
      this.$router.push({ path: '/sjrg' })
    },
    goSf() {
      this.$router.push({ path: '/sf' })
    },
    goFzqs() {
      this.$router.push({ path: '/fzqs' })
    },
    goJsyl() {
      this.$router.push({ path: '/jsyl' })
    },
    goWhy() {
      this.$router.push({ path: '/why' })
    },
  },
  mounted() {
    this.getServiceContent()
    this.getSuccessData()
    this.getIntroduction()
  },
  components: {
    Header,
    Banner,
    Footer,
    Affix,
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 769px) {
  .content {
    width: 78%;
    margin: 0 auto;

    .iconNavContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .icon {
        margin-top: 40px;
        text-align: center;
        font-size: 50px;
        color: #0a3daa;
        i {
          font-size: 50px;
        }
      }

      li {
        width: 100%;
        list-style: none;
        text-align: center;
        // border-right: 1px solid #f8f8f8;
        padding: 0 10px;
        position: relative;

        .title {
          line-height: 80px;
          font-weight: 700;
        }

        .text {
          font-size: 14px;
          color: #999999;
        }

        .left {
          width: 0;
          transition: all 0.3s linear;
          background-color: #0a3daa;
        }

        .right {
          width: 0;
          transition: all 0.3s linear;
          background-color: #0a3daa;
        }
      }

      // li:hover .left {
      //   height: 100%;
      //   background-color: #0a3daa;
      //   position: absolute;
      //   top: 0;
      //   width: 50%;
      //   z-index: -2;
      // }

      // li:hover .right {
      //   height: 100%;
      //   background-color: #0a3daa;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   width: 50%;
      //   z-index: -2;
      // }

      // li:hover {
      //   width: 100%;
      //   list-style: none;
      //   text-align: center;
      //   border-right: 1px solid #f8f8f8;
      //   padding: 0 10px;
      //   color: #ffffff;
      //   cursor: pointer;
      //   z-index: 9999;

      //   .icon {
      //     color: #ffffff;
      //   }

      //   .title {
      //     font-weight: 700;
      //   }

      //   .text {
      //     font-size: 14px;
      //     color: #ffffff;
      //   }
      // }
    }

    .content-text {
      margin-top: 20px;

      .title {
        font-size: 26px;
      }

      ul {
        margin-top: 10px;
        // display: flex;
        // justify-content: space-between;

        li {
          margin: 10px 0;
          display: flex;
          flex-direction: column;
          //justify-content: center;
          list-style: none;
          background-color: rgb(242, 242, 242);
          width: 100%;
          // height: 350px;
          padding: 60px 20px;
          cursor: pointer;
          // position: relative;
          z-index: 2;

          .content-title {
            //font-size: 18px;
            //font-family: "FangSong";
            margin: 3px 0;
            //color: #4673c4;
            font-weight: 600;
            color: #000;
          }

          ol {
            z-index: 2;
            //font-family: "FangSong";
            margin: 3px 10px;
            -webkit-line-clamp: 2; //设置几行
            display: -webkit-box; //设置为伸缩盒弹性盒子展示
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //设置超出部分以省略号展示
            -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
            line-height: 1.5;
            color: #999999;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
          }

          h4 {
            z-index: 2;
          }

          span {
            font-size: 14px;
            color: #999999;
            z-index: 2;
          }

          .text {
            -webkit-line-clamp: 3; //设置几行
            display: -webkit-box; //设置为伸缩盒弹性盒子展示
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //设置超出部分以省略号展示
            -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
            line-height: 1.5;
          }

          .ti {
            color: #999999;
            text-indent: 2em;
            //font-family: "FangSong";
            z-index: 2;
          }

          .center {
            margin: 40px 0;
          }
        }

        // li:nth-child(2) {
        //   margin: 0 30px;
        // }
      }

      @keyframes zzc {
        from {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        to {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          background: #0a3daa;
          opacity: 0.4;
        }
      }
      @keyframes showImg {
        from {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          visibility: hidden;
          transform: scale(0.9);
        }
        to {
          visibility: visible;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all 0.5s ease-out;
        }
      }

      @keyframes bi {
        from {
          display: flex;
          flex-direction: column;
          //justify-content: center;
          list-style: none;
          background-color: rgb(242, 242, 242);
          width: 33%;
          height: 350px;
          padding: 60px 20px;
          cursor: pointer;
        }
        to {
          display: flex;
          flex-direction: column;
          //justify-content: center;
          list-style: none;
          width: 33%;
          height: 350px;
          padding: 40px 20px;
          cursor: pointer;
          //background-image: url("../../assets/images/0335e0f16c10f5ad55bdcb78aa95e41.png");
          //background-repeat: no-repeat;
          //background-size: 100% 100%;
          background: #0a3daa;
          //color: #ffffff;
        }
      }

      // li:hover {
      //   animation-name: bi;
      //   animation-duration: 0.65s;
      //   animation-fill-mode: forwards;
      //   color: #ffffff;

      //   .content-title {
      //     color: #ffffff;
      //   }

      //   ol {
      //     color: #ffffff;
      //   }

      //   .ti {
      //     color: #ffffff;
      //   }

      //   h4 {
      //     color: #ffffff;
      //   }

      //   span {
      //     color: #ffffff;
      //   }
      // }

      // li:hover img {
      //   animation-name: showImg;
      //   animation-duration: 0.65s;
      //   animation-fill-mode: forwards;
      // }

      // li:hover .zzc {
      //   animation-name: zzc;
      //   animation-duration: 0.1s;
      //   animation-fill-mode: forwards;
      // }

      .btn {
        text-align: center;

        span {
          margin-top: 20px;
          font-size: 14px;
          padding: 10px 30px;
          border-radius: 2em;
          border: 1px solid #0a3daa;
          background-color: #0a3daa;
          color: rgb(255, 255, 255);
          display: inline-block;
          transition: all 0.25s ease-out 0s;

          i {
            font-weight: 600;
          }
        }

        span:hover {
          background-color: #fff;
          cursor: pointer;
          color: #0a3daa;
        }
      }
    }

    .container {
      margin-top: 80px;

      h2 {
        font-size: 42px;
        line-height: 42px;
        text-align: center;
        padding-bottom: 15px;
      }

      .en {
        color: #999999;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        padding-bottom: 40px;
      }

      .text {
        margin: 10px 0;

        p {
          color: #999999;
          line-height: 30px;
        }
      }

      .containerList {
        display: flex;
        justify-content: space-around;
        margin-top: 65px;

        li {
          list-style: none;
          text-align: center;

          .number {
            font-size: 62px;
            padding-right: 10px;
            color: #0a3daa;

            span {
            }
          }

          .textBlod {
            font-weight: bold;
          }
        }
      }
    }

    .media-center {
      margin-top: 80px;

      .title-box {
        // display: flex;
        // justify-content: space-between;

        .left {
          p {
            font-size: 22px;
            font-weight: 200;
          }

          .underline {
            width: 38px;
            height: 2px;
            background: #0a3daa;
            margin-top: 6px;
          }
        }

        .tabs {
          display: flex;
          flex-wrap: wrap;
          text-align: center;
          align-items: center;
          // margin-right: 30px;
          margin-top: 20px;

          li {
            padding: 8px 15px;
            margin: 0 5px;
          }

          // li {
          //   cursor: pointer;
          //   font-size: 13px;
          //   margin: 0 5px;
          //   padding: 6px 20px;
          // }

          li:nth-child(1) {
            background-color: #0a3daa;
            color: #fff;
          }

          // li:hover {
          //   background-color: #0a3daa;
          //   color: #fff;
          // }
        }
      }

      .media-box {
        // display: flex;
        // justify-content: space-between;
        margin-top: 50px;

        @keyframes you {
          from {
            cursor: pointer;
            width: 40%;
          }
          to {
            cursor: pointer;
            width: 40%;
            margin-left: 20px;
          }
        }
        @keyframes zzcTwo {
          from {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            background-color: #ffffff;
          }
          to {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }

        // .left:hover {
        //   animation-name: you;
        //   animation-duration: 0.5s;
        //   animation-fill-mode: forwards;
        // }

        // .left:hover .zzc {
        //   animation-name: zzcTwo;
        //   animation-duration: 0.5s;
        //   animation-fill-mode: forwards;
        // }

        .left {
          cursor: pointer;
          width: 100%;

          .img {
            width: 100%;
            height: 320px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            // .zzc {
            //   position: absolute;
            //   top: 0;
            //   width: 100%;
            //   height: 100%;
            //   opacity: 0.3;
            //   background-color: #ffffff;
            // }
          }

          .text {
            background: #0a3daa;
            padding: 28px 20px 28px 20px;
            display: flex;

            .date {
              width: 100%;
              display: flex;
              flex-direction: column;
              color: #fff;

              .day {
                font-size: 36px;
                font-weight: bold;
                color: #ffffff;
              }

              .year {
                font-size: 14px;
                margin-top: 5px;
              }
            }

            .text-content {
              // margin-left: 27px;

              .text-title {
                color: #fff;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                padding-bottom: 15px;
              }

              p {
                padding-top: 15px;
                color: #fff;
              }
            }
          }
        }

        .right {
          // margin-left: 70px;
          flex: 1;

          @keyframes pl {
            from {
            }
            to {
              padding-left: 20px;
              color: #0a3daa;
            }
          }

          .first {
            padding: 18px 0 0 0;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            .date {
              // width: 100%;
              display: flex;
              flex-direction: column;
              color: #999999;

              .day {
                font-size: 36px;
                font-weight: bold;
              }

              .year {
                font-size: 14px;
                margin-top: 5px;
              }
            }

            .text-content {
              // margin-bottom: 20px;
              width: 100%;

              .text-title {
                border-bottom: 1px solid #999999;
                padding-bottom: 15px;
              }

              p {
                padding-top: 15px;
                color: #999999;
              }
            }
          }

          .first:hover {
            animation-name: pl;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
        }

        ul {
          li:hover {
            animation-name: pl;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }

          .text {
            display: flex;
            justify-content: space-between;
            padding: 18px 0;
            border-bottom: 1px solid #999999;

            p {
              cursor: pointer;
            }

            span {
              color: #999999;
              font-size: 14px;
            }
          }

          .text:hover {
            color: #0a3daa;
          }
        }
      }

      .btn {
        text-align: center;

        span {
          margin-top: 20px;
          font-size: 14px;
          padding: 10px 30px;
          // border-radius: 2em;
          border: 1px solid #0a3daa;
          // background-color: #0a3daa;
          color: #0a3daa;
          display: inline-block;
          transition: all 0.25s ease-out 0s;

          i {
            font-weight: 600;
          }
        }

        span:hover {
          background-color: #0a3daa;
          cursor: pointer;
          color: #fff;
        }
      }
    }

    .cooperation {
      margin-top: 80px;

      .title {
        font-size: 32px;
      }

      span {
        font-size: 14px;
        color: #999999;
      }

      .cooperation-img {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        height: 420px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        align-items: center;

        li {
          cursor: pointer;
          padding: 10px 5px;

          img {
            width: 240px;
            height: 150px;
          }
        }

        li:hover {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}
@media screen and (min-width: 770px) {
  .content {
    width: 78%;
    margin: 0 auto;

    .iconNavContent {
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;
      height: 200px;
      // box-shadow: 0px 1px 2px #e4e4e4;

      .icon {
        margin-top: 40px;
        text-align: center;
        font-size: 50px;
        color: #0a3daa;
        i {
          font-size: 50px;
        }
      }

      li {
        width: 100%;
        list-style: none;
        text-align: center;
        // border-right: 1px solid #f8f8f8;
        padding: 0 10px;
        position: relative;

        .title {
          line-height: 80px;
          font-weight: 700;
        }

        .text {
          font-size: 14px;
          color: #999999;
        }

        .left {
          width: 0;
          transition: all 0.3s linear;
          background-color: #0a3daa;
        }

        .right {
          width: 0;
          transition: all 0.3s linear;
          background-color: #0a3daa;
        }
      }

      li:hover .left {
        height: 100%;
        background-color: #0a3daa;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: -2;
      }

      li:hover .right {
        height: 100%;
        background-color: #0a3daa;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        z-index: -2;
      }

      li:hover {
        width: 100%;
        list-style: none;
        text-align: center;
        border-right: 1px solid #f8f8f8;
        padding: 0 10px;
        color: #ffffff;
        cursor: pointer;
        z-index: 9999;

        .icon {
          color: #ffffff;
        }

        .title {
          font-weight: 700;
        }

        .text {
          font-size: 14px;
          color: #ffffff;
        }
      }
    }

    .content-text {
      margin-top: 80px;

      .title {
        font-size: 26px;
      }

      ul {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        li {
          display: flex;
          flex-direction: column;
          //justify-content: center;
          list-style: none;
          background-color: rgb(242, 242, 242);
          width: 33%;
          // height: 350px;
          padding: 60px 20px;
          cursor: pointer;
          position: relative;
          z-index: 2;

          .content-title {
            //font-size: 18px;
            //font-family: "FangSong";
            margin: 3px 0;
            //color: #4673c4;
            font-weight: 600;
            color: #000;
          }

          ol {
            z-index: 2;
            //font-family: "FangSong";
            // margin: 3px 10px;
            -webkit-line-clamp: 2; //设置几行
            display: -webkit-box; //设置为伸缩盒弹性盒子展示
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //设置超出部分以省略号展示
            -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
            line-height: 1.5;
            color: #999999;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
          }

          h4 {
            z-index: 2;
          }

          span {
            font-size: 14px;
            color: #999999;
            z-index: 2;
          }

          .text {
            -webkit-line-clamp: 3; //设置几行
            display: -webkit-box; //设置为伸缩盒弹性盒子展示
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //设置超出部分以省略号展示
            -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
            line-height: 1.5;
          }

          .ti {
            color: #999999;
            text-indent: 2em;
            //font-family: "FangSong";
            z-index: 2;
          }

          .center {
            margin: 40px 0;
          }
        }

        li:nth-child(2) {
          margin: 0 30px;
        }
      }

      @keyframes zzc {
        from {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        to {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          background: #0a3daa;
          opacity: 0.4;
        }
      }
      @keyframes showImg {
        from {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          visibility: hidden;
          transform: scale(0.9);
        }
        to {
          visibility: visible;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all 0.5s ease-out;
        }
      }

      @keyframes bi {
        from {
          display: flex;
          flex-direction: column;
          //justify-content: center;
          list-style: none;
          background-color: rgb(242, 242, 242);
          width: 33%;
          // height: 350px;
          padding: 60px 20px;
          cursor: pointer;
        }
        to {
          display: flex;
          flex-direction: column;
          //justify-content: center;
          list-style: none;
          width: 33%;
          // height: 350px;
          padding: 40px 20px;
          cursor: pointer;
          //background-image: url("../../assets/images/0335e0f16c10f5ad55bdcb78aa95e41.png");
          //background-repeat: no-repeat;
          //background-size: 100% 100%;
          background: #0a3daa;
          //color: #ffffff;
        }
      }

      li:hover {
        animation-name: bi;
        animation-duration: 0.65s;
        animation-fill-mode: forwards;
        color: #ffffff;

        .content-title {
          color: #ffffff;
        }

        ol {
          color: #ffffff;
        }

        .ti {
          color: #ffffff;
        }

        h4 {
          color: #ffffff;
        }

        span {
          color: #ffffff;
        }
      }

      li:hover img {
        animation-name: showImg;
        animation-duration: 0.65s;
        animation-fill-mode: forwards;
      }

      li:hover .zzc {
        animation-name: zzc;
        animation-duration: 0.1s;
        animation-fill-mode: forwards;
      }

      .btn {
        text-align: center;

        span {
          margin-top: 20px;
          font-size: 14px;
          padding: 10px 30px;
          border-radius: 2em;
          border: 1px solid #0a3daa;
          background-color: #0a3daa;
          color: rgb(255, 255, 255);
          display: inline-block;
          transition: all 0.25s ease-out 0s;

          i {
            font-weight: 600;
          }
        }

        span:hover {
          background-color: #fff;
          cursor: pointer;
          color: #0a3daa;
        }
      }
    }

    .container {
      margin-top: 80px;

      h2 {
        font-size: 42px;
        line-height: 42px;
        text-align: center;
        padding-bottom: 15px;
      }

      .en {
        color: #999999;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        padding-bottom: 40px;
      }

      .text {
        margin: 10px 0;

        p {
          color: #999999;
          line-height: 30px;
        }
      }

      .containerList {
        display: flex;
        justify-content: space-around;
        margin-top: 65px;

        li {
          list-style: none;
          text-align: center;

          .number {
            font-size: 62px;
            padding-right: 10px;
            color: #0a3daa;

            span {
            }
          }

          .textBlod {
            font-weight: bold;
          }
        }
      }
    }

    .media-center {
      margin-top: 80px;

      .title-box {
        display: flex;
        justify-content: space-between;

        .left {
          p {
            font-size: 22px;
            font-weight: 200;
          }

          .underline {
            width: 38px;
            height: 2px;
            background: #0a3daa;
            margin-top: 6px;
          }
        }

        .tabs {
          display: flex;
          align-items: center;
          margin-right: 30px;

          li {
            cursor: pointer;
            font-size: 13px;
            margin: 0 5px;
            padding: 6px 20px;
          }

          li:nth-child(1) {
            background-color: #0a3daa;
            color: #fff;
          }

          li:hover {
            background-color: #0a3daa;
            color: #fff;
          }
        }
      }

      .media-box {
        display: flex;
        // justify-content: space-between;
        margin-top: 50px;

        @keyframes you {
          from {
            cursor: pointer;
            width: 40%;
          }
          to {
            cursor: pointer;
            width: 40%;
            margin-left: 20px;
          }
        }
        @keyframes zzcTwo {
          from {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            background-color: #ffffff;
          }
          to {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }

        .left:hover {
          animation-name: you;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }

        .left:hover .zzc {
          animation-name: zzcTwo;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }

        .left {
          cursor: pointer;
          width: 40%;

          .img {
            width: 100%;
            height: 320px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }

            .zzc {
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0.3;
              background-color: #ffffff;
            }
          }

          .text {
            background: #0a3daa;
            padding: 28px 20px 28px 40px;
            display: flex;

            .date {
              width: 100%;
              display: flex;
              flex-direction: column;
              color: #fff;

              .day {
                font-size: 36px;
                font-weight: bold;
                color: #ffffff;
              }

              .year {
                font-size: 14px;
                margin-top: 5px;
              }
            }

            .text-content {
              margin-left: 27px;

              .text-title {
                color: #fff;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                padding-bottom: 15px;
              }

              p {
                padding-top: 15px;
                color: #fff;
              }
            }
          }
        }

        .right {
          margin-left: 70px;
          flex: 1;

          @keyframes pl {
            from {
            }
            to {
              padding-left: 20px;
              color: #0a3daa;
            }
          }

          .first {
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            .date {
              // width: 100%;
              display: flex;
              flex-direction: column;
              color: #999999;

              .day {
                font-size: 36px;
                font-weight: bold;
              }

              .year {
                font-size: 14px;
                margin-top: 5px;
              }
            }

            .text-content {
              margin-bottom: 20px;
              width: 85%;

              .text-title {
                border-bottom: 1px solid #999999;
                padding-bottom: 15px;
              }

              p {
                padding-top: 15px;
                color: #999999;
              }
            }
          }

          .first:hover {
            animation-name: pl;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
        }

        ul {
          li:hover {
            animation-name: pl;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }

          .text {
            display: flex;
            justify-content: space-between;
            padding: 18px 0;
            border-bottom: 1px solid #999999;

            p {
              cursor: pointer;
            }

            span {
              color: #999999;
              font-size: 14px;
            }
          }

          .text:hover {
            color: #0a3daa;
          }
        }
      }

      .btn {
        text-align: center;

        span {
          margin-top: 20px;
          font-size: 14px;
          padding: 10px 30px;
          // border-radius: 2em;
          border: 1px solid #0a3daa;
          // background-color: #0a3daa;
          color: #0a3daa;
          display: inline-block;
          transition: all 0.25s ease-out 0s;

          i {
            font-weight: 600;
          }
        }

        span:hover {
          background-color: #0a3daa;
          cursor: pointer;
          color: #fff;
        }
      }
    }

    .cooperation {
      margin-top: 80px;

      .title {
        font-size: 32px;
      }

      span {
        font-size: 14px;
        color: #999999;
      }

      .cooperation-img {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        height: 420px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        align-items: center;

        li {
          cursor: pointer;
          padding: 10px 5px;

          img {
            width: 240px;
            height: 150px;
          }
        }

        li:hover {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}
.home {
  width: 100%;
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

// .content {
//   width: 78%;
//   margin: 0 auto;

//   .iconNavContent {
//     display: flex;
//     // flex-wrap: wrap;
//     justify-content: space-between;
//     height: 200px;
//     // box-shadow: 0px 1px 2px #e4e4e4;

//     .icon {
//       margin-top: 40px;
//       text-align: center;
//       font-size: 50px;
//       color: #0a3daa;
//       i {
//         font-size: 50px;
//       }
//     }

//     li {
//       width: 100%;
//       list-style: none;
//       text-align: center;
//       // border-right: 1px solid #f8f8f8;
//       padding: 0 10px;
//       position: relative;

//       .title {
//         line-height: 80px;
//         font-weight: 700;
//       }

//       .text {
//         font-size: 14px;
//         color: #999999;
//       }

//       .left {
//         width: 0;
//         transition: all 0.3s linear;
//         background-color: #0a3daa;
//       }

//       .right {
//         width: 0;
//         transition: all 0.3s linear;
//         background-color: #0a3daa;
//       }
//     }

//     li:hover .left {
//       height: 100%;
//       background-color: #0a3daa;
//       position: absolute;
//       top: 0;
//       width: 50%;
//       z-index: -2;
//     }

//     li:hover .right {
//       height: 100%;
//       background-color: #0a3daa;
//       position: absolute;
//       top: 0;
//       right: 0;
//       width: 50%;
//       z-index: -2;
//     }

//     li:hover {
//       width: 100%;
//       list-style: none;
//       text-align: center;
//       border-right: 1px solid #f8f8f8;
//       padding: 0 10px;
//       color: #ffffff;
//       cursor: pointer;
//       z-index: 9999;

//       .icon {
//         color: #ffffff;
//       }

//       .title {
//         font-weight: 700;
//       }

//       .text {
//         font-size: 14px;
//         color: #ffffff;
//       }
//     }
//   }

//   .content-text {
//     margin-top: 80px;

//     .title {
//       font-size: 26px;
//     }

//     ul {
//       margin-top: 10px;
//       display: flex;
//       justify-content: space-between;

//       li {
//         display: flex;
//         flex-direction: column;
//         //justify-content: center;
//         list-style: none;
//         background-color: rgb(242, 242, 242);
//         width: 33%;
//         height: 350px;
//         padding: 60px 20px;
//         cursor: pointer;
//         position: relative;
//         z-index: 2;

//         .content-title {
//           //font-size: 18px;
//           //font-family: "FangSong";
//           margin: 3px 0;
//           //color: #4673c4;
//           font-weight: 600;
//           color: #000;
//         }

//         ol {
//           z-index: 2;
//           //font-family: "FangSong";
//           margin: 3px 10px;
//           -webkit-line-clamp: 2; //设置几行
//           display: -webkit-box; //设置为伸缩盒弹性盒子展示
//           overflow: hidden; //超出隐藏
//           text-overflow: ellipsis; //设置超出部分以省略号展示
//           -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
//           line-height: 1.5;
//           color: #999999;
//         }

//         img {
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           visibility: hidden;
//         }

//         h4 {
//           z-index: 2;
//         }

//         span {
//           font-size: 14px;
//           color: #999999;
//           z-index: 2;
//         }

//         .text {
//           -webkit-line-clamp: 3; //设置几行
//           display: -webkit-box; //设置为伸缩盒弹性盒子展示
//           overflow: hidden; //超出隐藏
//           text-overflow: ellipsis; //设置超出部分以省略号展示
//           -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
//           line-height: 1.5;
//         }

//         .ti {
//           color: #999999;
//           text-indent: 2em;
//           //font-family: "FangSong";
//           z-index: 2;
//         }

//         .center {
//           margin: 40px 0;
//         }
//       }

//       li:nth-child(2) {
//         margin: 0 30px;
//       }
//     }

//     @keyframes zzc {
//       from {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 2;
//       }
//       to {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 2;
//         background: #0a3daa;
//         opacity: 0.4;
//       }
//     }
//     @keyframes showImg {
//       from {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         visibility: hidden;
//         transform: scale(0.9);
//       }
//       to {
//         visibility: visible;
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         transition: all 0.5s ease-out;
//       }
//     }

//     @keyframes bi {
//       from {
//         display: flex;
//         flex-direction: column;
//         //justify-content: center;
//         list-style: none;
//         background-color: rgb(242, 242, 242);
//         width: 33%;
//         height: 350px;
//         padding: 60px 20px;
//         cursor: pointer;
//       }
//       to {
//         display: flex;
//         flex-direction: column;
//         //justify-content: center;
//         list-style: none;
//         width: 33%;
//         height: 350px;
//         padding: 40px 20px;
//         cursor: pointer;
//         //background-image: url("../../assets/images/0335e0f16c10f5ad55bdcb78aa95e41.png");
//         //background-repeat: no-repeat;
//         //background-size: 100% 100%;
//         background: #0a3daa;
//         //color: #ffffff;
//       }
//     }

//     li:hover {
//       animation-name: bi;
//       animation-duration: 0.65s;
//       animation-fill-mode: forwards;
//       color: #ffffff;

//       .content-title {
//         color: #ffffff;
//       }

//       ol {
//         color: #ffffff;
//       }

//       .ti {
//         color: #ffffff;
//       }

//       h4 {
//         color: #ffffff;
//       }

//       span {
//         color: #ffffff;
//       }
//     }

//     li:hover img {
//       animation-name: showImg;
//       animation-duration: 0.65s;
//       animation-fill-mode: forwards;
//     }

//     li:hover .zzc {
//       animation-name: zzc;
//       animation-duration: 0.1s;
//       animation-fill-mode: forwards;
//     }

//     .btn {
//       text-align: center;

//       span {
//         margin-top: 20px;
//         font-size: 14px;
//         padding: 10px 30px;
//         border-radius: 2em;
//         border: 1px solid #0a3daa;
//         background-color: #0a3daa;
//         color: rgb(255, 255, 255);
//         display: inline-block;
//         transition: all 0.25s ease-out 0s;

//         i {
//           font-weight: 600;
//         }
//       }

//       span:hover {
//         background-color: #fff;
//         cursor: pointer;
//         color: #0a3daa;
//       }
//     }
//   }

//   .container {
//     margin-top: 80px;

//     h2 {
//       font-size: 42px;
//       line-height: 42px;
//       text-align: center;
//       padding-bottom: 15px;
//     }

//     .en {
//       color: #999999;
//       font-size: 24px;
//       line-height: 24px;
//       text-align: center;
//       padding-bottom: 40px;
//     }

//     .text {
//       margin: 10px 0;

//       p {
//         color: #999999;
//         line-height: 30px;
//       }
//     }

//     .containerList {
//       display: flex;
//       justify-content: space-around;
//       margin-top: 65px;

//       li {
//         list-style: none;
//         text-align: center;

//         .number {
//           font-size: 62px;
//           padding-right: 10px;
//           color: #0a3daa;

//           span {
//           }
//         }

//         .textBlod {
//           font-weight: bold;
//         }
//       }
//     }
//   }

//   .media-center {
//     margin-top: 80px;

//     .title-box {
//       display: flex;
//       justify-content: space-between;

//       .left {
//         p {
//           font-size: 22px;
//           font-weight: 200;
//         }

//         .underline {
//           width: 38px;
//           height: 2px;
//           background: #0a3daa;
//           margin-top: 6px;
//         }
//       }

//       .tabs {
//         display: flex;
//         align-items: center;
//         margin-right: 30px;

//         li {
//           cursor: pointer;
//           font-size: 13px;
//           margin: 0 5px;
//           padding: 6px 20px;
//         }

//         li:nth-child(1) {
//           background-color: #0a3daa;
//           color: #fff;
//         }

//         li:hover {
//           background-color: #0a3daa;
//           color: #fff;
//         }
//       }
//     }

//     .media-box {
//       display: flex;
//       // justify-content: space-between;
//       margin-top: 50px;

//       @keyframes you {
//         from {
//           cursor: pointer;
//           width: 40%;
//         }
//         to {
//           cursor: pointer;
//           width: 40%;
//           margin-left: 20px;
//         }
//       }
//       @keyframes zzcTwo {
//         from {
//           position: absolute;
//           top: 0;
//           width: 100%;
//           height: 100%;
//           opacity: 0.4;
//           background-color: #ffffff;
//         }
//         to {
//           position: absolute;
//           top: 0;
//           width: 100%;
//           height: 100%;
//           opacity: 0;
//         }
//       }

//       .left:hover {
//         animation-name: you;
//         animation-duration: 0.5s;
//         animation-fill-mode: forwards;
//       }

//       .left:hover .zzc {
//         animation-name: zzcTwo;
//         animation-duration: 0.5s;
//         animation-fill-mode: forwards;
//       }

//       .left {
//         cursor: pointer;
//         width: 40%;

//         .img {
//           width: 100%;
//           height: 320px;
//           position: relative;

//           img {
//             width: 100%;
//             height: 100%;
//           }

//           .zzc {
//             position: absolute;
//             top: 0;
//             width: 100%;
//             height: 100%;
//             opacity: 0.3;
//             background-color: #ffffff;
//           }
//         }

//         .text {
//           background: #0a3daa;
//           padding: 28px 20px 28px 40px;
//           display: flex;

//           .date {
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             color: #fff;

//             .day {
//               font-size: 36px;
//               font-weight: bold;
//               color: #ffffff;
//             }

//             .year {
//               font-size: 14px;
//               margin-top: 5px;
//             }
//           }

//           .text-content {
//             margin-left: 27px;

//             .text-title {
//               color: #fff;
//               border-bottom: 1px solid rgba(255, 255, 255, 0.2);
//               padding-bottom: 15px;
//             }

//             p {
//               padding-top: 15px;
//               color: #fff;
//             }
//           }
//         }
//       }

//       .right {
//         margin-left: 70px;
//         flex: 1;

//         @keyframes pl {
//           from {
//           }
//           to {
//             padding-left: 20px;
//             color: #0a3daa;
//           }
//         }

//         .first {
//           cursor: pointer;
//           display: flex;
//           justify-content: space-between;

//           .date {
//             // width: 100%;
//             display: flex;
//             flex-direction: column;
//             color: #999999;

//             .day {
//               font-size: 36px;
//               font-weight: bold;
//             }

//             .year {
//               font-size: 14px;
//               margin-top: 5px;
//             }
//           }

//           .text-content {
//             margin-bottom: 20px;
//             width: 85%;

//             .text-title {
//               border-bottom: 1px solid #999999;
//               padding-bottom: 15px;
//             }

//             p {
//               padding-top: 15px;
//               color: #999999;
//             }
//           }
//         }

//         .first:hover {
//           animation-name: pl;
//           animation-duration: 0.5s;
//           animation-fill-mode: forwards;
//         }
//       }

//       ul {
//         li:hover {
//           animation-name: pl;
//           animation-duration: 0.5s;
//           animation-fill-mode: forwards;
//         }

//         .text {
//           display: flex;
//           justify-content: space-between;
//           padding: 18px 0;
//           border-bottom: 1px solid #999999;

//           p {
//             cursor: pointer;
//           }

//           span {
//             color: #999999;
//             font-size: 14px;
//           }
//         }

//         .text:hover {
//           color: #0a3daa;
//         }
//       }
//     }

//     .btn {
//       text-align: center;

//       span {
//         margin-top: 20px;
//         font-size: 14px;
//         padding: 10px 30px;
//         // border-radius: 2em;
//         border: 1px solid #0a3daa;
//         // background-color: #0a3daa;
//         color: #0a3daa;
//         display: inline-block;
//         transition: all 0.25s ease-out 0s;

//         i {
//           font-weight: 600;
//         }
//       }

//       span:hover {
//         background-color: #0a3daa;
//         cursor: pointer;
//         color: #fff;
//       }
//     }
//   }

//   .cooperation {
//     margin-top: 80px;

//     .title {
//       font-size: 32px;
//     }

//     span {
//       font-size: 14px;
//       color: #999999;
//     }

//     .cooperation-img {
//       text-align: center;
//       width: 80%;
//       margin: 0 auto;
//       height: 420px;
//       display: flex;
//       flex-wrap: wrap;
//       margin-top: 50px;
//       align-items: center;

//       li {
//         cursor: pointer;
//         padding: 10px 5px;

//         img {
//           width: 240px;
//           height: 150px;
//         }
//       }

//       li:hover {
//         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
//       }
//     }
//   }
// }

.Affix {
  z-index: 9999;
  position: fixed;
  width: 55px;
  right: 0;
  top: 50%;
  transform: translate(-20%, -80%);

  ul {
    color: #fff;

    li {
      cursor: pointer;
      background-color: #0a3daa;
      height: 55px;
      text-align: center;
      line-height: 55px;
      margin: 2px 0;
    }
  }
}
</style>
