<template>
  <div>

    <div class="footer">
      <div class="footer-content">
        <div class="left">
          <ul class="solution" v-for="(item, index) in footerData" :key="index">
            <li @click="skipPage(item.url)">{{ item.name }}</li>
            <li @click="skipPage(ele.url)" v-for="(ele, i) in item.children" :key="i">{{ ele.title }}</li>
          </ul>
        </div>
      </div>
    </div>

 

  
    <div class="copyright">
     
      <img  src="@/assets/images/beian.aeb30c.png" />
      <a href="https://beian.miit.gov.cn/" class="beian">浙ICP备2023017554号-1</a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'index',
  data: function () {
    return {
      footerData: [

        {
          name: '关于我们',
          url: '/home',
        },
        {
          name: '隐私协议',
          url: '/mediaCenter',

        },

        {
          name: '联系我们',
          children:[
          {
              icon: 'el-icon-s-order',
              title: '微信客服：obtaindown',
            },
            {
              icon: 'el-icon-s-order',
              title: 'QQ客服：1817292770',
            },
          ]
        },

      ],

    }
  },
  methods: {
    //跳转页面
    skipPage(url) {
      this.$router.push({ path: url })
    },
    goPage() {
      this.$router.push({ path: '/projectConsultation' })
    },
  },
  props: {
    isFooterImg: {
      type: Boolean,
      default: true,
    },
    isImg: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 769px) {
  .tabbar {
    width: 100%;
    display: flex;
    color: #fff;
    background-color: #0a3daa;
    position: fixed;
    bottom: 0;
    justify-content: space-around;
    z-index: 9999;

    .tabbar-item {
      text-align: center;
    }
  }

  .footer {
    background-color: rgb(255, 255, 255);
    padding: 50px 0px;

    .footer-content {
      margin: 0 auto;
      width: 78%;
      // display: flex;
      text-align: center;

      .left {
        width: 23.666667%;
        display: flex;
        justify-content: space-between;

        .solution {
          li {
            cursor: pointer;
            color: #f3f3f3;
            margin: 4px 0;
            font-size: 14px;
          }

          li:nth-child(1) {
            color: #0e0c0c;
            margin: 12px 0;
            font-size: 16px;
            margin-bottom: 20px;
          }

          li:hover {
            color:#0e0c0c;
          }
        }
      }

      .right {
        margin-top: 10px;
        text-align: center;
        // margin-left: 20px;

        .hotline {
          margin: 0 auto;
          padding: 10px 0;
          font-size: 16px;
          line-height: 30px;
          color: #0e0c0c;
          width: 200px;
          border: 1px solid #0e0c0c;
          text-align: center;
          margin-bottom: 10px;
          cursor: pointer;

          p {
            i {
              font-size: 22px;
            }
          }
        }

        .code {
          img {
            width: 150px;
            height: 150px;
            margin-top: 10px;
          }
        }

        span {
          color: #0e0c0c;
          font-size: 12px;
        }
      }
    }

    .text-link {
      margin: 0 auto;
      width: 78%;

      ul {
        display: flex;
        border-bottom: 1px solid #0e0c0c;
        padding: 30px 0;

        li {
          color: #0e0c0c;
          margin: 0 10px;
        }

        li:nth-child(1) {
          color: #0e0c0c;
        }

        li:nth-child(2):after {
          content: '/';
        }

        li:nth-child(3):after {
          content: '/';
        }

        li:nth-child(4):after {
          content: '/';
        }

        li:hover {
          color: #0e0c0c;
          cursor: pointer;
        }

        li:nth-child(1):hover {
          color: #0e0c0c;
          cursor: auto;
        }
      }
    }
  }

}

@media screen and (min-width: 770px) {
  .tabbar {
    display: none;
  }

  .footer {
    background-color: rgba(17, 17, 17, 0.829);
    padding: 50px 0px;
    display: flex;

    .footer-content {
      margin: 0 auto;
      width: 78%;
      text-align: center;

      .left {
        width: 23.666667%;
        display: flex;
        justify-content: space-between;

        .solution {
          li {
            // 联系我们下面的字体颜色
            cursor: pointer;
            color: #aaada9;
            margin: 12px 0;
            font-size: 14px;
          }
            // 背景颜色
          li:nth-child(1) {
            color: #fdfdfd;
            margin: 12px 0;
            font-size: 16px;
            text-align: center;
            margin-bottom: 10px;
          }

          li:hover {
            color: #0e0c0c;
          }
        }
      }

      .right {
        margin-top: 10px;
        text-align: center;
        margin-left: 230px;

        .hotline {
          padding: 10px 0;
          font-size: 16px;
          line-height: 30px;
          color: #0e0c0c;;
          width: 200px;
          border: 1px solid #0e0c0c;
          text-align: center;
          margin-bottom: 10px;
          cursor: pointer;

          p {
            i {
              font-size: 22px;
            }
          }
        }

        .code {
          img {
            width: 150px;
            height: 150px;
            margin-top: 10px;
          }
        }

        span {
          color: #b84c4c;
          font-size: 12px;
        }
      }
    }

  }

  // --------------------------------

  .footer-ICP {
    background-color: rgb(51, 51, 51);
    padding: 50px 0px;

    .footer-content-ICP {
      margin: 0 auto;
      width: 78%;
      display: flex;
      // background-color: rgb(180, 13, 13);
    }
  }

 


  .copyright {
    height: 65px;
    line-height: 65px;
    text-align: center;
    font-size: 15px;
  }

}

li {
  list-style: none;
}

.banner-text {
  margin-top: 10px;
  height: 400px;
  background-image: url('../../assets/images/底部.jpg');
  background-size: 100% 100%;
  position: relative;
  text-align: center;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;

    .title {
      font-size: 32px;
    }

    .text {
      font-size: 18px;
      margin: 20px 0;
    }

    button {
      color: #0a3daa;
      background: rgb(255, 255, 255);
      line-height: 36px;
      font-size: 16px;
      margin-top: 35px;
      padding: 2px 25px;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      letter-spacing: 0.1em;
      border-radius: 1.6rem;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 6px, rgba(0, 0, 0, 0.09) 0px 3px 6px;
      cursor: pointer;
    }

    button:hover {
      transition: all 0.3s linear;
      color: #ffffff;
      background-color: #0a3daa;
    }
  }
}
</style>
